import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import R from 'ramda';
import Heading from '@cotyorg/ccx-atoms/Heading/Heading';
import Paragraph from '@cotyorg/ccx-atoms/Paragraph';
import SectionHeader from '../../atoms/SectionHeader';
import StandardLayout from '../../templates/StandardLayout';

const NotFound = ({ displayMessage, sectionHeadings }) => (
  <div className="error-page">
    <StandardLayout>
      <div className="error-page__main">
        <div className="grid-container">
          <SectionHeader>
            <Heading extraClass="section__title" level={1}>
              {R.path(['notFound', 'heading'], sectionHeadings)}
            </Heading>
          </SectionHeader>
          <Paragraph alignment="center">{displayMessage}</Paragraph>
        </div>
      </div>
    </StandardLayout>
  </div>
);

NotFound.propTypes = {
  displayMessage: PropTypes.string,
  sectionHeadings: PropTypes.objectOf(PropTypes.object),
};

export default connect(({ content }) => ({
  sectionHeadings: R.prop('sectionHeadings', content),
}))(NotFound);
